import {Component, computed, effect, inject, input, model, Signal, signal, WritableSignal} from '@angular/core';
import {StockService} from '@services/stock.service';
import {debounceTime, forkJoin, map, mergeMap, Observable, of, pipe} from "rxjs";
import {TableLazyLoadEvent} from "primeng/table";
import {Page} from '@typedefs/page';

import {StockSearch} from '@model/search/stock-search';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {ArticleSearch} from '@model/search/article-search';
import {Stock} from '@model/stock';
import {Warehouse} from '@model/warehouse';
import {Article} from '@model/article';
import {ArticleCategory} from '@model/article-category';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Pagination} from '@services/pagination';
import {ArticleStorageCondition} from '@model/article-storage-condition';
import {UserService} from '@services/user.service';
import {ArticleCategorySearch} from '@model/search/article-category-search';
import {ArticleStorageConditionSearch} from '@model/search/article-storage-condition-search';
import {PalletSearch} from "@model/search/pallet-search";
import {StockPallet} from "@model/stock-pallet";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {MovementDialogComponent} from "@components/movement/new-movement-dialog/movement-dialog.component";
import {Movement} from "@model/movement";
import {MovementTypeService} from "@services/movement-type.service";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {MovementType} from "@model/movement-type";
import {InterWarehouseTransferService} from "@services/inter-warehouse-transfer-service";
import {InterWarehouseTransfer} from "@model/inter-warehouse-transfer";
import {MessageService} from "primeng/api";
import {MovementKind} from "@typedefs/stock-rest";

@Component({
  selector: 'foodbank-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.scss']
})
export class StockListComponent {

  stockSearch = model<StockSearch>();
  bulkMovementKind = input<MovementKind>();
  emptyMessage = input("");
  bulkMovementActive = model(false);
  bulkMovementType = model<MovementType>();
  bulkMovementNeedsTargetWarehouse: Signal<boolean>;
  bulkMovementTargetWarehouse = model<Warehouse>();
  bulkMovementComment = signal<string | undefined>(undefined);

  bulkMovementTargetWarehouseSearch: Signal<WarehouseSearch>;

  tableSizeStyleClass = localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '';
  pagination: WritableSignal<Pagination>;

  selectedStocks = signal<Stock[]>([]);

  // searches for stock page
  selectedWarehouses = signal<Warehouse[]>([]);
  selectedArticles = signal<Article[]>([]);
  selectedArticleCategories = signal<ArticleCategory[]>([]);
  selectedArticleStorageConditions = signal<ArticleStorageCondition[]>([]);

  selectedPallet = signal<StockPallet | undefined>(undefined);
  actualStockSearch: Signal<StockSearch>;

  stockPage: Signal<Page<Stock> | undefined>;
  // searches for filters
  filterArticleStorageConditionSearch = signal<ArticleStorageConditionSearch>({});
  filterArticleCategorySearch = signal<ArticleCategorySearch>({});
  filterWarehouseSearch: Signal<WarehouseSearch>;
  filterPalletSearch = signal<PalletSearch>({
    withStock: true,
  });

  filterArticleSearch: Signal<ArticleSearch>;

  fullDescriptionContains = signal<string | undefined>(undefined);

  movementDialogRef?: DynamicDialogRef;

  #dialogService = inject(DialogService);
  #stockService = inject(StockService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #movementTypeService = inject(MovementTypeService);
  #interWarehouseTransferService = inject(InterWarehouseTransferService);
  #messageService = inject(MessageService);

  constructor() {
    this.pagination = this.#paginationService.getDefaultPaginationSignal();

    const currentUserCompany$ = this.#userService.getCurrentUserCompany$();
    const currentUserCompany = toSignal(currentUserCompany$);

    this.filterWarehouseSearch = computed(() => ({
      company: currentUserCompany(),
      active: true
    }));

    this.filterArticleSearch = computed(() => ({
        articleCategorySearch: this.filterArticleCategorySearch(),
        articleStorageConditionSearch: this.filterArticleStorageConditionSearch(),
      })
    );

    this.filterPalletSearch = signal({
      withStock: true,
    })

    const fullDescriptionContains$ = toObservable(this.fullDescriptionContains);
    const debouncedFullDescriptionContains$ = fullDescriptionContains$.pipe(debounceTime(500));
    const debouncedFullDescriptionContains = toSignal(debouncedFullDescriptionContains$);

    this.actualStockSearch = computed(() => ({
        ...this.stockSearch(),
        warehouseSearch: {
          warehouses: this.selectedWarehouses(),
          company: currentUserCompany(),
        },
        articleSearch: {
          articles: this.selectedArticles(),
          articleCategorySearch: {
            articleCategories: this.selectedArticleCategories(),
          },
          articleStorageConditionSearch: {
            articleStorageConditions: this.selectedArticleStorageConditions(),
          }
        },
        stockPalletSearchDto: {
          idContains: this.selectedPallet()?.id,
        },
        fullDescriptionContains: debouncedFullDescriptionContains(),
      })
    );

    const stockPageParams: Signal<[StockSearch, Pagination]> = computed(() => [this.actualStockSearch(), this.pagination()])

    this.stockPage = pipeSignal(stockPageParams, pipe(
        mergeMap(([stockSearch, pagination]) => this.#stockService.findStock(stockSearch, pagination)),
      )
    );

    this.bulkMovementTargetWarehouseSearch = computed(() => ({
      company: currentUserCompany(),
      active: true
    }));

    this.bulkMovementNeedsTargetWarehouse = computed(() => this.needsTargetWarehouse(this.bulkMovementType()));

    effect(() => {
      const movementKind = this.bulkMovementKind();
      if (movementKind) {
        this.#movementTypeService.getMovementType$(movementKind)
          .subscribe(inputMovementType => this.bulkMovementType.set(inputMovementType));
      }
    });
  }

  loadStockList(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  typing(stock: Stock): Stock {
    return stock;
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  openAddStockDialog(stock: Stock) {
    const movementType$ = this.#movementTypeService.getMovementType$('INVENTORY_PLUS');
    const currentUserCompany$ = this.#userService.getCurrentUserCompany$();
    const currentUser$ = this.#userService.getCurrentUser$();
    const movement: Partial<Movement> = {
      stock$: of(stock),
      stockPallet$: stock.pallet$,
      warehouse$: stock.warehouse$,
      movementType$: movementType$,
      date: new Date(),
      dateTime: new Date(),
      company$: currentUserCompany$,
      supplier$: stock.supplier$,
      deliverBeforeDate: stock.deliverBeforeDate,
      fullDescription: stock.fullDescription,
      location: stock.location,
      internalBatchName: stock.internalBatch,
      supplierBatchName: stock.supplierBatch,
      article$: stock.article$,
      user$: currentUser$,
      reception$: of(undefined),
      organization$: of(undefined),
      unitWeight: stock.unitWeight,
      unitsPerParcel: stock.unitsPerParcel,
      unitGrossWeight: stock.unitGrossWeight,
      timestamp: new Date(),

    }
    this.movementDialogRef = this.#dialogService.open(MovementDialogComponent, {
      header: 'Create movement',
      width: '40%',
      data: movement,
    });

    this.movementDialogRef.onClose.subscribe(_ => this.refresh(true));
  }

  needsTargetWarehouse(bulkMovementType?: MovementType) {
    return bulkMovementType?.movementKind === 'INTER_WAREHOUSE_TRANSFER';
  }

  private refresh(clearSelection: boolean) {
    if (clearSelection) {
      this.selectedStocks.set([]);
    }
    this.pagination.update(() => ({...this.pagination()}));
  }

  bulkMovementReady() {
    return (this.bulkMovementNeedsTargetWarehouse() && this.bulkMovementTargetWarehouse()) && this.selectedStocks().length > 0;
  }

  bulkMoveSelection() {
    const targetWarehouse = this.bulkMovementNeedsTargetWarehouse() ? this.bulkMovementTargetWarehouse() : undefined;
    const bulkMovementType = this.bulkMovementType();
    if (!bulkMovementType || !targetWarehouse) {
      return;
    }
    const interwarehouseTransfers: InterWarehouseTransfer[] = this.selectedStocks()
      .map(stock => ({
        stock$: of(stock),
        targetWarehouse$: of(targetWarehouse),
        comment: this.bulkMovementComment(),
        stockPrevision$: of(undefined),
        movement$: of(undefined),
      }));
    const createdInterwarehouseTransfers: Observable<InterWarehouseTransfer>[] = interwarehouseTransfers
      .map(stockinterwarehouseTransfer => this.#interWarehouseTransferService.createInterWarehouseTransfer$(stockinterwarehouseTransfer));

    forkJoin(createdInterwarehouseTransfers).pipe(
      map(createdInterwarehouseTransfers => this.#notifyInterWarehouseTransfers(createdInterwarehouseTransfers)),
    ).subscribe(_ => this.refresh(true));
  }

  #notifyInterWarehouseTransfers(interwarehouseTransfers: InterWarehouseTransfer[]) {
    this.#messageService.add({severity: 'success', summary: 'Transfer created', detail: `Created ${interwarehouseTransfers.length} bulk movements.`});
  }
}
