import {Component, computed, inject, input, model, Signal, signal, WritableSignal} from '@angular/core';
import {SupplierSearch} from '@model/search/supplier-search';
import {Supplier} from '@model/supplier';
import {SupplierService} from '@services/supplier.service';
import {PaginationService} from "@services/pagination.service";
import {Pagination} from "@services/pagination";
import {loadSignalFromObservable} from "@util/foodbanks-signal-rxjs-interop";

@Component({
  selector: 'foodbank-supplier-selection',
  exportAs: 'supplierSelection',
  templateUrl: './supplier-selection.component.html',
  styleUrls: ['./supplier-selection.component.scss']
})
export class SupplierSelectionComponent {

  ngModel = model<Supplier>();
  required = input(false);
  supplierSearch = input<SupplierSearch>({}); // you could for example create a supplier filter to get suppliers of a specific user

  nameFilter = signal('');

  suppliers: Signal<Supplier[] | undefined>;
  pagination: WritableSignal<Pagination>;
  loading = signal(true);

  #supplierService = inject(SupplierService);
  #paginationService = inject(PaginationService);

  constructor() {
    this.pagination = this.#paginationService.getDefaultComponentPaginationSignal();

    const actualSupplierSearch: Signal<SupplierSearch> = computed(() => ({
      ...this.supplierSearch(),
      nameContains: this.nameFilter(),
    }));

    const supplierPage = loadSignalFromObservable(() => this.#supplierService.findSuppliers$(actualSupplierSearch(), this.pagination()), this.loading);

    this.suppliers = computed(() => supplierPage()?.content);
  }

  clear() {
    this.ngModel.set(undefined);
  }

}
