<p-dropdown [(ngModel)]="ngModel"
            [options]="movementTypePage()?.content || []"
            optionLabel="id"
            [placeholder]="loading ? 'Loading...' : 'None'"
            [filter]="true"
            filterMatchMode="contains"
            filterBy="movementKind"
            [showClear]="true"
            dataKey="movementKind"
            appendTo="body"
            class="w-12">
  <ng-template pTemplate="selectedItem">
    <foodbank-movement-type ngDefaultControl [ngModel]="ngModel()" />
  </ng-template>
  <ng-template let-movementType pTemplate="item">
    <foodbank-movement-type ngDefaultControl [ngModel]="movementType" />
  </ng-template>
</p-dropdown>
