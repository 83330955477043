<div class="card p-fluid" *ngIf="(movement$ | async) as movement">
  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0">Movement*</label>
    <foodbank-movement-type-single-selection ngDefaultControl class="col-12 md:col-10" [movementTypeSearch]="movementTypeSearch()" [ngModel]="(movement.movementType$ | async) || undefined" (ngModelChange)="handleMovementTypeSelected(movement, $event)"/>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0">Supplier*</label>
    <foodbank-supplier-selection ngDefaultControl class="col-12 md:col-10" id="supplier" [supplierSearch]="{}" (ngModelChange)="handleSupplierSelected(movement, $event)" />
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0">Article*</label>
    <foodbank-article-selection ngDefaultControl class="col-12 md:col-10" [ngModel]="(movement.article$ | async) || undefined" (ngModelChange)="handleArticleSelected(movement, $event)" [disabled]="!(movement.supplier$ | async)"/>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Comment</label>
    <div class="col-12 md:col-10">
      <input type="text" pInputText [(ngModel)]="movement.comment">
    </div>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0">Warehouse*</label>
    <ng-container *ngIf="(warehouseSearch$ | async) as warehouseSearch">
      <foodbank-warehouse-selection class="col-12 md:col-10" [warehouseSearch]="warehouseSearch" [ngModel]="(movement.warehouse$ | async) || undefined" (ngModelChange)="handleWarehouseSelected(movement, $event)" ngDefaultControl/>
    </ng-container>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0">Organization*</label>
    <ng-container *ngIf="{}">
      <foodbank-organization-selection class="col-12 md:col-10" [organizationSearch]="{}" [ngModel]="(movement.organization$ | async) || undefined" (ngModelChange)="handleOrganizationSelected(movement, $event)" ngDefaultControl/>
    </ng-container>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Location</label>
    <div class="col-12 md:col-10">
      <input type="text" pInputText [(ngModel)]="movement.location">
    </div>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Pallet</label>
    <div class="col-12 md:col-10">
      <foodbank-pallet-single-selection [selectedPallet]="(movement.stockPallet$ | async) || undefined" (onPalletSelected)="handlePalletSelected(movement, $event)" [palletSearch]="{withStock: true}" ngDefaultControl>
        >
      </foodbank-pallet-single-selection>
      <div *ngIf="(movement.stock$ | async) as stock; else noStock">
        {{ stock.quantity }} kg in stock #{{ stock.id }}
      </div>
    </div>
    <ng-template #noStock>
      <div>
        No stock found for pallet, please select another.
      </div>
    </ng-template>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Date*</label>
    <foodbank-date-picker ngDefaultControl class="col-12 md:col-10" [ngModel]="date" [showSelectionOptions]="false" (onSelect)="handleDateSelected(movement, $event)"/>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Supplier batch</label>
    <div class="col-12 md:col-10">
      <input type="text" pInputText [(ngModel)]="movement.supplierBatchName">
    </div>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>DLC*</label>
    <foodbank-date-picker ngDefaultControl class="col-12 md:col-10" [ngModel]="bestBeforeDate" [showSelectionOptions]="false" (onSelect)="handleBestBeforeDateSelected(movement, $event)"/>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>DDM*</label>
    <foodbank-date-picker ngDefaultControl class="col-12 md:col-10" [ngModel]="expirationDate" [showSelectionOptions]="false" (onSelect)="handleExpirationDateSelected(movement, $event)"/>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Weight (kg)*</label>
    <div class="col-12 md:col-10">
      <input type="number" pInputText [(ngModel)]="movement.quantity">
    </div>
  </div>

  <div class="field grid">
    <div class="col-offset-3 col-3">
      <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="saveAndClose(movement)" />
    </div>
    <div class="col-3">
      <p-button label="Close" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()" />
    </div>
  </div>
</div>
