<p-table #dt [value]="(((userPage$ | async)?.content) || [])" responsiveLayout="scroll" [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="((userPage$ | async)?.totalElements || 0)"
         [globalFilterFields]="['depot']" [paginator]="true" [rowsPerPageOptions]="[25,50,100]"
         [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true" (onLazyLoad)="loadUsers($event)" [filters]="filters" [rowHover]="true" dataKey="id"
sortMode="multiple">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 class="m-0">Users</h2>
      <span class="report-block mt-2 md:mt-0 p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" placeholder="Search..." class="w-full sm:w-auto"/>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="ID">
        <span i18n>#</span>
        <p-sortIcon field="ID" />
      </th>
      <th pSortableColumn="NAME">
        <span i18n>Name</span>
        <p-sortIcon field="NAME" />
      </th>
      <th pSortableColumn="WAREHOUSE">
        <span i18n>Warehouse</span>
        <p-sortIcon field="WAREHOUSE" />
      </th>
      <th pSortableColumn="ORGANIZATION" pResizableColumn>
        <span i18n>Organization</span>
        <p-sortIcon field="ORGANIZATION" />
      </th>
      <th pSortableColumn="LANGUAGE">
        <span i18n>Language</span>
        <p-sortIcon field="LANGUAGE" />
      </th>
      <th pSortableColumn="TYPE">
        <span i18n>Type</span>
        <p-sortIcon field="TYPE" />
      </th>
      <th>
        <span i18n>Rights</span>
      </th>
      <th></th>
    </tr>
    <tr>
      <th></th>
      <th></th>
      <th>
        <p-columnFilter field="warehouses" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <ng-container *ngIf="(warehouseSearch$ | async) as warehouseSearch">
              <foodbank-warehouses-selection [selectedWarehouses]="value"
                [warehouseSearch]="warehouseSearch"
                (onWarehousesSelected)="filter($event)"
               />
            </ng-container>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-user>
    <tr *ngIf="identity(user) as user">
      <td>
        {{ user.id }}
      </td>
      <td>
        {{ user.name }}
      </td>
      <td>
        <foodbank-warehouse ngDefaultControl [ngModel]="(user.warehouse$ | async) || undefined" />
      </td>
      <td style="max-width: 300px; overflow: hidden;" class="white-space-normal">
        <foodbank-organization ngDefaultControl [organization]="(user.organization$ | async) || undefined" />
      </td>
      <td>
        <foodbank-language ngDefaultControl [language]="user.language" />
      </td>
      <td>
        <foodbank-user-type ngDefaultControl [userType]="user.type" />
      </td>
      <td>
        {{ user.rights }}
      </td>
      <td>
        <div class="flex">
          <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2"></button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
