<ng-container *ngIf="(owners$ | async) as owners">
  <ng-container *ngIf="preparationItemSearch.preparation as preparation">
    <div class="owner" *ngFor="let owner of owners">
      <div class="page-header">
        <span class="top-row">
          <img src="./assets/layout/images/foodbank.png" alt="Foodbank">
          <span class="title">
            <h2>Banque Alimentaire de Belgique</h2>
            <h2>{{ (preparation.company$ |async)?.compName }}</h2>
          </span>
        </span>
        <span class="preparation">
          <span class="top-row">
            <span class="preparation-details">
              <p>Bon de préparation n° {{ preparation.id }}</p>
              <p>Zone de préparation {{ preparation.location }}</p>
            </span>
            <span class="preparation-warehouse">
              <foodbank-warehouse ngDefaultControl [ngModel]="(preparation.warehouse$ | async) || undefined" [showId]="true" />
            </span>
          </span>
        </span>
      </div>
      <div class="organization">
        <span class="top-row">
          <span class="preparation-organization">
            <foodbank-organization ngDefaultControl
              [organization]="owner.organization"
              [showId]="true"
              [showAddress]="true"
             />
          </span>
          <span class="preparation-organization-barcode">
            <ngx-barcode6 [bc-format]="'CODE128'"
              [bc-value]="preparation.id + '-' + owner.organization.id"
              [bc-display-value]="true"
              [bc-height]="30"
              [bc-width]="1"
              [bc-font-size]="12"
              [bc-margin-bottom]="0"
              [bc-text-margin]="0"
              bc-element-type="img"
             />
          </span>
        </span>
      </div>
      <div class="preparation-items">
        <ng-container *ngIf="(ownerPreparationItemsMap$ | async) as organizationPreparationItemsMap">
          <ng-container *ngIf="organizationPreparationItemsMap.get(owner) as preparationItems">
            <foodbank-fead-preparation-report-table [preparationItems]="preparationItems"
              [showBarCode]="showBarCode"
              [showOrganization]="false"
             />
          </ng-container>
        </ng-container>
      </div>
      <div class="returnables">
        <div class="returnables-header">
          Vidanges
        </div>
        <div class="returnables-list" *ngIf="(ownerReturnablesMap$ | async) as ownerReturnablesMap">
          <ng-container *ngFor="let returnable of ownerReturnablesMap.get(owner)">
            <div class="returnable">
              <div class="returnable-content">
                {{ returnable.id }}
                {{ returnable.languageNameMap['DUTCH'] }}
              </div>
              <div class="returnable-check"></div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="signature">
        Ce document a été traité par ...................
      </div>
    </div>
  </ng-container>
</ng-container>
