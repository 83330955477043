import {Component, inject, input, model} from '@angular/core';
import {LanguageService} from '@services/language.service';
import {Supplier} from '@model/supplier';

@Component({
  selector: 'foodbank-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent {

  ngModel = model<Supplier>();
  showId = input(true);

  private languageService = inject(LanguageService);

  constructor() {
  }

}
