<p-dropdown *foodbankLanguage="'fr'" [ngModel]="ngModel"
            (onChange)="select($event.value)"
            [options]="(articles$ | async) || []"
            optionLabel="id"
            [placeholder]="loading ? 'Loading...' : 'None'"
            [filter]="true"
            filterMatchMode="contains"
            filterBy="id,nameFr"
            (onClear)="clear()"
            [showClear]="true"
            dataKey="id"
            appendTo="body"
            class="w-12"
            [disabled]="disabled">
  <ng-template pTemplate="selectedItem">
    <foodbank-article ngDefaultControl [ngModel]="ngModel" />
  </ng-template>
  <ng-template let-article pTemplate="item">
    <foodbank-article ngDefaultControl [ngModel]="article" />
  </ng-template>
</p-dropdown>
<p-dropdown *foodbankLanguage="'nl'" [ngModel]="ngModel"
            (onChange)="select($event.value)"
            [options]="(articles$ | async) || []"
            optionLabel="id"
            [placeholder]="loading ? 'Chargement...' : 'None'"
            [filter]="true"
            filterMatchMode="contains"
            filterBy="id,nameNl"
            (onClear)="clear()"
            [showClear]="true"
            dataKey="id"
            appendTo="body"
            class="w-12"
            [disabled]="disabled">
  <ng-template pTemplate="selectedItem">
    <ng-container *ngIf="ngModel">
      <foodbank-article ngDefaultControl [ngModel]="ngModel" />
    </ng-container>
  </ng-template>
  <ng-template let-article pTemplate="item">
    <foodbank-article ngDefaultControl [ngModel]="article" />
  </ng-template>
</p-dropdown>
