<p-table #dt
         [value]="stockPage()?.content || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="stockPage()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="loadStockList($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         sortMode="multiple"
         [autoLayout]="true"
         dataKey="id"
         selectionMode="multiple"
         [(selection)]="selectedStocks"
         [multiSortMeta]="[{ field: 'ARTICLE_CATEGORY_ID', order: 1 }, { field: 'ARTICLE_ID', order: 1 }, { field: 'WAREHOUSE', order: 1 }]"
         [styleClass]="tableSizeStyleClass">

  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 class="m-0">Stock</h2>
      <span>
        <span>
          <label for="article-storage-condition-filter" class="mr-2" i18n>Storage condition</label>
          <foodbank-article-storage-condition-selection ngDefaultControl
            id="article-storage-condition-filter"
            [articleStorageConditionSearch]="filterArticleStorageConditionSearch() || {}"
            (onArticleStorageConditionSelected)="selectedArticleStorageConditions.set($event)" />
        </span>
      </span>
    </div>
    <div class="flex flex-column md:flex-row mt-5 justify-content-between">
      <div class="field">
        <p-panel class="pl-0 md:col-4 panel-header-white" [style]="{ 'min-width': '550px' }" [class.bulk-movements-inactive]="!bulkMovementActive()">
          <ng-template pTemplate="header">
            <div class="ml-1 gap-1 borde" *ngIf="bulkMovementActive()">
              <i class="pi pi-truck mr-2"></i>
              <span class="font-bold">Bulk movements settings</span>
            </div>
          </ng-template>

          <div class="p-2 fadeinup animation-duration-100 animation-iteration-1 transition-linear"  *ngIf="bulkMovementActive()">
            <div class="flex field justify-content-left align-items-center">
              <label class="w-4 mb-0 mr-2" for="movementType">Movement type:</label>
              <foodbank-movement-type-single-selection id="movementType" ngDefaultControl [(ngModel)]="bulkMovementType"/>
            </div>

            <div class="flex field justify-content-left align-items-center" *ngIf="bulkMovementNeedsTargetWarehouse()">
              <label class="w-4 mb-0 mr-2" for="targetWarehouse">Target warehouse:</label>
              <foodbank-warehouse-selection id="targetWarehouse" ngDefaultControl [(ngModel)]="bulkMovementTargetWarehouse" [warehouseSearch]="bulkMovementTargetWarehouseSearch()"/>
            </div>
          </div>
          <div class="flex flex-column md:flex-row md:justify-content-left md:align-items-center">
            <p-button icon="pi pi-truck" class="mr-3" label="Bulk move {{ selectedStocks().length }} item(s)" *ngIf="bulkMovementActive()" [disabled]="!bulkMovementReady()" (click)="bulkMoveSelection()"/>
            <p-toggleButton [(ngModel)]="bulkMovementActive" offIcon="pi pi-truck" onIcon="pi pi-sign-out" offLabel="Enter bulk movements mode" onLabel="Exit bulk movements mode" />
          </div>
        </p-panel>
      </div>
      <div class="field">
        <foodbank-table-size [(tableStyleClass)]="tableSizeStyleClass"/>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th style="width: 4rem" *ngIf="bulkMovementActive()">
        <p-tableHeaderCheckbox/>
      </th>
      <th pSortableColumn="ARTICLE_CATEGORY_ID">
        <span i18n>Category</span>
        <p-sortIcon field="ARTICLE_CATEGORY_ID" />
      </th>
      <th pSortableColumn="ARTICLE_ID" pResizableColumn>
        <span i18n>Article</span>
        <p-sortIcon field="ARTICLE_ID" />
      </th>
      <th pSortableColumn="WAREHOUSE">
        <span i18n>Warehouse</span>
        <p-sortIcon field="WAREHOUSE" />
      </th>
      <th pSortableColumn="RECEPTION_DATE">
        <span i18n>Reception date</span>
        <p-sortIcon field="RECEPTION_DATE" />
      </th>
      <th pSortableColumn="PALLET">
        <span i18n>Pallet</span>
        <p-sortIcon field="PALLET" />
      </th>
      <th pSortableColumn="QUANTITY">
        <span i18n>Quantity</span>
        <p-sortIcon field="QUANTITY" />
      </th>
      <th pSortableColumn="LOCATION">
        <span i18n>Location</span>
        <p-sortIcon field="LOCATION" />
      </th>
      <th>
        <span i18n>DLC</span>
      </th>
      <th>
        <span i18n>DLV</span>
      </th>
      <th>
        <span i18n>DLD</span>
      </th>
      <th>
        <span i18n>Unit Weight</span>
      </th>
      <th>
        <span i18n>Full description</span>
      </th>
      <th>
        <span i18n>Units per parcel</span>
      </th>
      <th>
        <span i18n>Unit Bruto Weight</span>
      </th>
      <th>
        <span i18n>Recept Origin</span>
      </th>
      <th>
        <span i18n>Preparation zone</span>
      </th>
    </tr>
    <tr>
      <th style="width: 4rem" *ngIf="bulkMovementActive()"></th>
      <th>
        <p-columnFilter field="articleCategories" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <foodbank-article-category-selection ngDefaultControl
              [selectedArticleCategories]="selectedArticleCategories()"
              [articleCategorySearch]="filterArticleCategorySearch() || {}"
              (onArticleCategorySelected)="selectedArticleCategories.set($event)"
             />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="articles" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <foodbank-article-multi-selection ngDefaultControl
              [selectedArticles]="selectedArticles()"
              [articleSearch]="filterArticleSearch() || undefined"
              (onArticleSelected)="selectedArticles.set($event)"
             />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="warehouses" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <foodbank-warehouses-selection ngDefaultControl
              [selectedWarehouses]="selectedWarehouses()"
              [warehouseSearch]="filterWarehouseSearch() || {}"
              (onWarehousesSelected)="selectedWarehouses.set($event)"
             />
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th>
        <p-columnFilter field="pallets" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <foodbank-pallet-single-selection
              ngDefaultControl
              [selectedPallet]="selectedPallet()"
              [palletSearch]="filterPalletSearch() || {}"
              (onPalletSelected)="selectedPallet.set($event)"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th>
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="fullDescriptionContains"/>
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-stock>
    <ng-container *ngIf="typing(stock) as stock">
      <tr>
        <td *ngIf="bulkMovementActive()">
          <p-tableCheckbox [value]="stock"/>
        </td>
        <td>
          <!-- TODO: any should go, it's there because article$ is not always defined correctly (undefined?), still passes ?. but then fails type checking -->
          <foodbank-article-category ngDefaultControl
            [articleCategory]="$any(stock.article$ | async)?.articleCategory$ | async" />
        </td>
        <td class="white-space-normal">
          <foodbank-article ngDefaultControl [ngModel]="(stock.article$ | async) || undefined" />
        </td>
        <td>
          <foodbank-warehouse ngDefaultControl [ngModel]="(stock.warehouse$ | async) || undefined" />
        </td>
        <td>
          {{ stock.receptionDate }}
        </td>
        <td>
          <ng-container *ngIf="(stock.pallet$ | async) as pallet">
            <foodbank-pallet ngDefaultControl [pallet]="pallet" [showHistory]="true" />
          </ng-container>
        </td>
        <td class="aligned-quantity">
          {{ stock.quantity | number: '1.2-2' }}
          <i class="pi pi-plus ml-4" (click)="openAddStockDialog(stock)"></i>
        </td>
        <td>
          {{ stock.location }}
        </td>
        <td>
          {{ stock.expirationDate }}
        </td>
        <td>
          {{ stock.bestBeforeDate }}
        </td>
        <td>
          {{ stock.deliverBeforeDate }}
        </td>
        <td class="aligned-quantity">
          {{ stock.unitWeight | number: '1.0-0' }}
        </td>
        <td>
          {{ stock.fullDescription }}
        </td>
        <td class="aligned-quantity">
          {{ stock.unitsPerParcel }}
        </td>
        <td class="aligned-quantity">
          {{ stock.unitGrossWeight | number: '1.0-0' }}
        </td>
        <td>
          {{ stock.sourceRecept }}
        </td>
        <td>
          {{ (stock.preparationZone$ | async)?.location }}
        </td>
      </tr>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="17"><span i18n>{{ emptyMessage() }}</span></td>
    </tr>

  </ng-template>
</p-table>
